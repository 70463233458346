import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleListingComponent } from './article-listing/article-listing.component';
import { ArticleViewPageComponent } from './article-view-page/article-view-page.component';
import { PageNotFoundComponent } from '../general/page-not-found/page-not-found.component';
import { CategoryGuard } from './category.guard';
import { ArticleLinkGuard } from './article-link.guard';
// const articleRoutes: Routes = [ 
//   // { path: ':category/:subcategory', component: ArticleListingComponent, canActivate: [CategoryGuard]}, 
//   {path:'all-articles',component: ArticleListingComponent},

//   { path: ':category/:article-link', component: ArticleViewPageComponent, canActivate: [ArticleLinkGuard] },         
//   { path: ':category/page/:page', component: ArticleListingComponent, canActivate: [CategoryGuard] }, 
//   { path: ':category', component: ArticleListingComponent, canActivate: [CategoryGuard]},  
//   // { path: ':category/:subcategory/:article-link', component: ArticleViewPageComponent, canActivate: [ArticleLinkGuard] },
//   // { path: ':category/:subcategory/page/:page', component: ArticleListingComponent, canActivate: [CategoryGuard]},
//   {path:'all-articles',component: ArticleListingComponent},
//   { path: 'page-not-found', component: PageNotFoundComponent },
//   { path: '**', redirectTo: 'page-not-found' }
// ];
const articleRoutes: Routes = [
  { path: 'academy/all-articles/page/:page', component: ArticleListingComponent, canActivate: [CategoryGuard] },
  { path: 'academy/all-articles', component: ArticleListingComponent, canActivate: [CategoryGuard] },
  { path: ':category/:article-link', component: ArticleViewPageComponent, canActivate: [ArticleLinkGuard] },
  { path: ':category/page/:page', component: ArticleListingComponent, canActivate: [CategoryGuard] },
  { path: ':category', component: ArticleListingComponent, canActivate: [CategoryGuard] },
  { path: ':category/:subcategory/:article-link', component: ArticleViewPageComponent, canActivate: [ArticleLinkGuard] },
  { path: ':category/:subcategory/page/:page', component: ArticleListingComponent , canActivate: [CategoryGuard]},
  { path: ':category/:subcategory', component: ArticleListingComponent , canActivate: [CategoryGuard]},
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found' }
];
// const articleRoutes: Routes = [
//   { path: 'academy/all-articles/page/:page', component: ArticleListingComponent },
//   { path: 'academy/all-articles', component: ArticleListingComponent },
//   { path: ':category/:article-link', component: ArticleViewPageComponent },
//   { path: ':category/page/:page', component: ArticleListingComponent },
//   { path: ':category', component: ArticleListingComponent },
//    { path: ':category/:subcategory/:article-link', component: ArticleViewPageComponent },
//   { path: ':category/:subcategory/page/:page', component: ArticleListingComponent },
//   { path: ':category/:subcategory', component: ArticleListingComponent },
//   { path: 'page-not-found', component: PageNotFoundComponent },
//   { path: '**', redirectTo: 'page-not-found' }
// ];

@NgModule({
  imports: [RouterModule.forChild(articleRoutes)],
  exports: [RouterModule]
})
export class ArticlesRoutingModule { }
