import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  private dropdownState: { [key: string]: boolean } = {};
  private closeTimeouts: { [key: string]: any } = {};

  isOpen(key: string): boolean {
    return this.dropdownState[key] || false;
  }

  openMenu(key: string) {
    this.dropdownState[key] = true;

    if (this.closeTimeouts[key]) {
      clearTimeout(this.closeTimeouts[key]);
    }
  }

  closeMenu(key: string) {
    this.closeTimeouts[key] = setTimeout(() => {
      this.dropdownState[key] = false;
    }, 300); // Adjust the delay as needed
  }

  cancelCloseMenu(key: string) {
    if (this.closeTimeouts[key]) {
      clearTimeout(this.closeTimeouts[key]);
    }
  }
}
