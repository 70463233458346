<div class="row mt-0 me-xxl-5 me-lg-0 me-xl-5 me-md-0 me-sm-0 pe-md-10 "
    *ngIf="!pageloading; else authorContentNotLoaded">
    <div *ngFor="let eachAuthor of authors;let i=index"
        class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-md-column flex-lg-column flex-xl-column flex-xxl-row flex-sm-column flex-xs-column author-col pb-3"
        (mouseenter)="showAuthorData[i]=!showAuthorData[i]" (mouseleave)="showAuthorData[i]=!showAuthorData[i]"
        (click)="showAuthorData[i]=!showAuthorData[i]">

        <!-- <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-md-column flex-lg-column flex-xl-column flex-xxl-row flex-sm-column flex-xs-column author-col "
        (mouseenter)="showAuthorData=!showAuthorData"  (mouseleave)="showAuthorData=!showAuthorData" (click)="showAuthorData=!showAuthorData"> -->
        <div *ngIf="eachAuthor?.fig" class="author-circle">
            <img class="author-img " width="90" height="90" src="{{authorImageUrl}}{{article_path}}/{{eachAuthor.fig}}"
                alt="author image">
        </div>

        <div class="author-description" [innerHtml]="setAuthorData(eachAuthor)" #yourDiv>
            <!-- <h5 class="author-name" [innerHtml]="eachAuthor?.name"></h5>
            <p class="author-content" [innerHtml]="eachAuthor?.affiliation"> -->
            <!-- </p> -->
        </div>
        <div *ngIf="showAuthorData[i]" class="author-wrapper">
            <div class="pt-3 pb-3 author-container ">
               <div class="row">
                <div *ngIf="eachAuthor?.fig" class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <div class="author-tooltip-image">
                        <!-- <img src="FS-pic.jpg" alt="author image" class="rounded" height="120" width="120"> -->
                        <img alt="author image" class="rounded" height="100" width="100"
                            src="{{authorImageUrl}}{{article_path}}/{{eachAuthor.fig}}">
                    </div>
                </div>
                <div *ngIf="eachAuthor?.fig" class="col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9"
                    [innerHtml]="setAuthorDataHover(eachAuthor)">
                </div>
                <div *ngIf="!eachAuthor?.fig" class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ps-4"
                    [innerHtml]="setAuthorDataHover(eachAuthor)">
                </div>
               </div>   
            </div>


        </div>
    </div>
</div>
<ng-template #authorContentNotLoaded>
    <div class="row mt-0 me-xxl-5 me-lg-0 me-xl-5 me-md-0 me-sm-0 pe-md-10 ">
        <div *ngFor="let eachAuthor of authorsLoadingArray| slice:0:4;let i=index "
            class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-md-column flex-lg-column flex-xl-column flex-xxl-row flex-sm-column flex-xs-column author-col pb-3">
            <div class="d-flex  flex-md-column flex-lg-column flex-xl-column flex-xxl-row flex-sm-column flex-xs-column ">
                <div class="author-img skeleton  skeleton-circle">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div class="author-description ms-4 skeleton-description">
                    <p class="skeleton skeleton-text skeleton-heading"></p>
                    <div class="w-100">
                        <p class="skeleton skeleton-text">&nbsp;</p>
                        <p class="skeleton skeleton-text">&nbsp;&nbsp;&nbsp;</p>
                        <p class="skeleton skeleton-text">&nbsp;&nbsp;&nbsp;</p>
                        <p class="skeleton skeleton-text skeleton-text-80">&nbsp;&nbsp;</p>
                    </div>
                </div>
            </div>
            <!-- <div class="author-circle d-flex flex-row ">
                <div class="col-4">
                    <div class="author-img skeleton  skeleton-circle">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>

                <div class="author-description ">
                    <p class="skeleton skeleton-text skeleton-heading"></p>
                    <div class="w-100">
                        <p class="skeleton skeleton-text">&nbsp;</p>
                        <p class="skeleton skeleton-text">&nbsp;&nbsp;&nbsp;</p>
                        <p class="skeleton skeleton-text">&nbsp;&nbsp;&nbsp;</p>
                        <p class="skeleton skeleton-text skeleton-text-80">&nbsp;&nbsp;</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</ng-template>