import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { env } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class JournalService {

    constructor(private http: HttpClient) { }

    getJournals(queryParams: any): Observable<any> {
        return this.http.get<any>(env.siteApiUrl + 'web/journals', {
            params: queryParams
        })
    }
}
