import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareButton } from 'ngx-sharebuttons/button';

import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild,AfterViewInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { env } from '../../config/config';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgClass, NgFor, NgIf, NgStyle, isPlatformBrowser } from '@angular/common';


@Component({
    standalone: true,
    selector: 'app-chapter-view-page',
    imports: [ShareButton, FontAwesomeModule,  NgIf, NgFor,  NgStyle, NgClass],
    templateUrl: './chapter-view-page.component.html',
    styleUrl: './chapter-view-page.component.scss'
})
export class ChapterViewPageComponent implements AfterViewInit {
    @ViewChild('contentLoaded') contentLoaded!: ElementRef<HTMLDivElement>;
    @ViewChild('introduction', { static: false }) introductionSection!: ElementRef;
    @ViewChild('modeOfAction', { static: false }) modeOfActionSection!: ElementRef;
    @ViewChild('dapsone', { static: false }) dapsoneSection!: ElementRef;
    @ViewChild('references', { static: false }) referencesSection!: ElementRef;

    sharedUrl = '';
    journal_id = '';
    subscriptions: Subscription[] = [];
  
    articleCoverImageUrl = '';
    surgical_content: any[] = [];
    category = '';
    article_link = '';
    parser!: DOMParser;
    html_new_content = '';
    authorImages: string[] = [];
    html_new_content_dupe: any;
    relatedArticle: any[] = [];
    download_url: string = '';
    download_pdf = '';
    activeViewFont = "normal";
    downloadText = '';
    categorydocheadloading = true;
    pageLoading = true;
    articleTitleLoading = true;
    coverImgLoading = true;
    authorViewLoading = true;
    articleDoiLoading = true;
    articleContetLoading=true;
    relatedArticleLoading=true;
    contentLoadingArray = new Array(8).fill(0);
    articleFilePath: string;
    heading: string;
    caption: string;
    body: string;
    isModalOpen = false;
    div_data: Element;
    activeSection: string = '';
    activeTab = 'tab1';
    chapter_info=false;
   
    isFixed=false;
    containerWidth: number = 0;
    constructor(
   
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private router: Router,

        // private modalservice: NgbModal
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.parser = new DOMParser();
        }
     
    }

    ngOnInit() {
        const routesub = this.route.params.subscribe(() => {
          
        });
        this.subscriptions.push(routesub);
    }

   

    openDyslexic(mode: string) {
        this.activeViewFont = mode;
        if (mode == 'dyslexic') this.contentLoaded.nativeElement.classList.add('dyslexic-regular');
        else if (mode == 'normal') this.contentLoaded.nativeElement.classList.remove('dyslexic-regular');

    }

    openDownloadLink() {
        window.open(this.download_url, '_blank');
    }

    //to display modal and to navigate to reference
    refClick(event: Event) {
        const aTagEle = event.target as HTMLInputElement;
        const refAttrVal = aTagEle?.getAttribute("attr-ref-type") as string;
        
    
        if (aTagEle.classList.contains('graphic')) {  // Check if the clicked element has the 'graphic' class
            const href: string = aTagEle.getAttribute('href') as string;
            const result = href.substring(0, href.indexOf('.')); // Extract the content before the first period
    
            const figElements = document.querySelectorAll(".fig"); // Query all elements with class 'fig'
            
            if(figElements.length > 0){
                figElements.forEach((eachFig) => {
                    const imgElement = eachFig.querySelector('img.graphic');
                    if (imgElement) {
                        const imgHref = imgElement.getAttribute('href');
                        if (imgHref && imgHref.includes(result)) {
                            this.div_data = eachFig; // Store the reference to the matched fig element
                        }
                    }
                });
        
            }
            else{
                this.div_data = aTagEle;
            }


            if (this.div_data) {
                // Clone the div_data to ensure the original remains in the DOM
                this.div_data = this.div_data.cloneNode(true) as HTMLElement;
                this.isModalOpen = true;
            }
        }
    }

    loadModal(event: Event){

        const aTagEle = event.target as HTMLInputElement;
        this.div_data = aTagEle;
        if (this.div_data) {
            // Clone the div_data to ensure the original remains in the DOM
            this.div_data = this.div_data.cloneNode(true) as HTMLElement;
            this.isModalOpen = true;
        }
    }
    

    closeJoinModal() {
        this.isModalOpen = false;
    }

    showContent(section: string) {
        this.activeSection = section;
    }
    setActiveTab(tab: string) {
        this.activeTab = tab;
     
    }
    onTagClick(tag: { type: string, value: string }) {
        
        const queryParams: any = {
           // search: this.searchValue
        };
    
        if (tag.type === 'contentType') {
            queryParams['contentType'] = tag.value;
        } else if (tag.type === 'speciality') {
            queryParams['speciality'] = tag.value;
        } else if (tag.type === 'category') {
            queryParams['category'] = tag.value;
        } 
        this.router.navigate(['/academy', this.category, 'page', '1'], { queryParams });
    
    }
    
   
    ngAfterViewInit() {
        // ViewChild elements are now available here
      }
    //   scrollToSection(section: string) {
    //     console.log(section);
    //     switch (section) {
    //       case 'introduction':
    //         this.introductionSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    //         break;
    //       case 'modeOfAction':
    //         this.modeOfActionSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    //         break;
    //       case 'dapsone':
    //         this.dapsoneSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    //         break;
    //       case 'references':
    //         this.referencesSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    //         break;
    //       default:
    //         console.warn('Unknown section:', section);
    //     }
    //   }
    scrollToSection(sectionId: string) {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth' });
        } else {
        //   console.warn('Section not found:', sectionId);
        }
      }
      downloadPDF(): void {
        window.open('https://www.internationaltextbookofleprosy.org/sites/default/files/ITL_5_2%20FINAL.pdf', '_blank');
      }
          
    onScroll(event: any) {
       
        const scrollPosition = window.scrollY;
        const targetElement = document.getElementById('chapter-tab');
     
        const containerElement = targetElement?.parentElement;
        const endContentElement = document.getElementById('article-content'); // The right column to detect when scrolling past it
        
        if (containerElement && endContentElement) {
          const targetPosition = containerElement.offsetTop;
          const endPosition = endContentElement.offsetHeight;
          this.containerWidth = containerElement.offsetWidth;
  
          // Check if the user has scrolled past the container but not beyond the main content
          if (scrollPosition >= targetPosition && scrollPosition <= endPosition) {
            this.isFixed = true; // Apply fixed position
          } else {
            this.isFixed = false; // Remove fixed position
          }
         
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach(x => {
            if (!x.closed) {
                x.unsubscribe();
            }
        });
    }

}