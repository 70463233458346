<div class="container-fluid">



    <section class="container p-0 h-100">
        <div class="error-page mb-5">

            <div class="error-content">

                <div class="not-found-container">
                    <img src="404_error.png" alt="404 error image" width="55%"/>
                    <!-- <div class="not-found-title">Page Not Found</div> -->
                    <!-- <p class="not-found-message">May be the page you're looking for is not found or never existed.</p> -->
                    <p class="not-found-message">Sorry, we can't find the page you're looking for. Please try checking the URL for typos or head over to our <a routerLink="/" class="not-found-home-link">homepage</a> instead.</p>
                </div>

            </div>

        </div>

    </section>

</div>