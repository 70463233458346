<!-- <div class=" " > -->
  <nav class="navbar navbar-expand-lg navbar-fixed-top container-fluid custom-main-padding sticky-container " #navbarContainer>
    <div class="container-fluid p-0">
      <div  class="navbar-brand pl-5 pr-5 d-flex align-items-center" routerLink="/" >
        <img id="logo" src="IToL_logo.png" *ngIf="valueSet" class="logo-image" alt="" width="150"
          height="82">
          <h1 id="logoText" class="logo-text ps-3 d-xs-none " >International Textbook of Leprosy</h1>
          <h1 class="logo-text ps-3 d-xs-block d-sm-none d-md-none d-lg-none d-xl-none d-xxl-none">ITL</h1>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        (click)="toggleMobileView()">
        <span *ngIf="!isMenuOpen" class="navbar-toggler-icon">

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">

            <path d="M3 6h18M3 12h18M3 18h18" />
          </svg>
        </span>
        <span *ngIf="isMenuOpen">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7a.996.996 0 1 0-1.41 1.41L10.59 12l-4.88 4.88a.996.996 0 1 0 1.41 1.41L12 13.41l4.88 4.88a.996.996 0 1 0 1.41-1.41L13.41 12l4.88-4.88a.996.996 0 0 0 0-1.41z" />
          </svg>
        </span>
      </button>

      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-5 mb-2 mb-lg-0 custom-navbar">
          <li class="nav-item" *ngFor="let eachMenu of menus ;let index=index"
            [ngClass]="{ 'dropdown megamenu': eachMenu.subMenus.length > 0 }"
            (mouseenter)="openMenu(eachMenu.identifier);">
            <div *ngIf="eachMenu.subMenus.length === 0">
              <a class="nav-link" aria-current="page" [routerLink]="eachMenu.url"
              routerLinkActive="nav-link-active-link" 
                [routerLinkActiveOptions]="{exact: true}" (click)="getClickedSubMenuParent(eachMenu)"
                [ngClass]="{'nav-link-active-link': router.isActive(eachMenu.url, true) }"
                (mouseenter)="closeOtherMenu(eachMenu.identifier)" (mouseleave)="closeMenu(eachMenu.identifier)">{{
                eachMenu.menu_name }} </a>
            </div>

          
          </li>

        </ul>
      </div>

      <div *ngIf="mobileView" class="collapse navbar-collapse nav-mobile nav-item-mobile" id="navbarSupportedContent">
        <ul class="navbar-nav custom-navbar-mobile">
          <li class="nav-item nav-item-mobile" *ngFor="let eachMenu of menus"
            [ngClass]="{ 'dropdown mobile-dropdown': eachMenu.subMenus.length > 0 }">

            <!-- Check if the menu has subMenus -->
            <div *ngIf="eachMenu.subMenus.length == 0; ">
              <a class="sub" aria-current="page" routerLink="{{eachMenu.url}}"
                (click)="toggleMobileView();">{{eachMenu.menu_name}}</a>
            </div>


          </li>
        </ul>

      </div>

    </div>
  </nav>
<!-- </div> -->
