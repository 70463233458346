import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterServiceStateService {

  private filterState = new BehaviorSubject<{ surgical_speciality: string[], content_type: string[] }>({
    surgical_speciality: [],
    content_type: []
  });

  getFilterState(): Observable<{ surgical_speciality: string[], content_type: string[] }> {
    return this.filterState.asObservable();
  }

  setFilterState(filters: { surgical_speciality: string[], content_type: string[] }) {
    this.filterState.next(filters);
  }
}
