import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ArticleService } from '../services/article.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleLinkGuard implements CanActivate {

  constructor(private router: Router, private articleService: ArticleService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const category = route.params['category'];
    const subcategory = route.params['subcategory'];
    const articleLink = route.params['article-link'];
    // Define logic based on the presence of subcategory
    if (category&&subcategory&&articleLink) {
      // Handle subcategory scenario
      return this.validateArticleLink(category, subcategory, articleLink);
    } else if (category&&articleLink) {
      // Handle simple category/article-link scenario
      return this.validateArticleLink(category,null,articleLink);
    }
    else{
      return this.validateArticleLink(category, subcategory,null);

    }
  }

  private validateArticleLink(category: string, subcategory: string | null, articleLink: string): boolean | UrlTree {
    // Example logic, replace with your actual validation logic
    // Validate against your articleService or another source
    
    // For example, check if the article exists in the service:
    const articleExists = this.articleService.checkArticleExists(category, subcategory, articleLink);
    if (articleExists) {
      return true;
    } else {
      return this.router.createUrlTree(['/page-not-found']);
    }
  }
}
