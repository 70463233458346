import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ArticleService } from '../services/article.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryGuard implements CanActivate {

  categories: any;
  categoryTitles: any[];

  constructor(private router: Router, private articleService: ArticleService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const category = route.params['category'];
    const subcategory = route.params['subcategory'];
  
    try {
      const response: any = await this.articleService.getAllCategories().toPromise();
      
      // Find the academy menu and filter submenus
      const academyMenu = response.find((menu: any) => menu.identifier === 'academy');
      if (academyMenu) {
        const filteredSubMenus = academyMenu.subMenus.filter((subMenu: any) => subMenu.identifier !== 'scientific_surgery');
        const scientificSurgeryMenu = academyMenu.subMenus.find((subMenu: any) => subMenu.identifier === 'scientific_surgery');
  
        if (scientificSurgeryMenu) {
          filteredSubMenus.push(...scientificSurgeryMenu.subMenus);
        }
  
        this.categories = filteredSubMenus;
        this.categoryTitles = ['All', ...this.categories.map((subMenu: any) => subMenu.identifier.replace(/_/g, '-'))];
      }
  
      // Check if the current category or subcategory is valid
      if (
        this.categoryTitles.includes(category) || 
        this.categoryTitles.includes(subcategory) || 
        category === "all-articles" ||
        state.url.includes('page')
      ) {
        return true;
      } else {
        return this.router.createUrlTree(['/page-not-found']);
      }
    } catch (error) {
      return this.router.createUrlTree(['/page-not-found']);
    }
  }
}  