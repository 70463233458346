import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, viewChild } from '@angular/core';

@Component({
    selector: 'app-modal-fig',
    standalone: true,
    templateUrl: './modal-fig.component.html',
    styleUrl: './modal-fig.component.scss'
})
export class ModalFigComponent implements AfterViewInit {

    @Output() close = new EventEmitter<void>();
    @Input() div_data: Element;
    @ViewChild('modalContent')modalDiv:ElementRef<HTMLDivElement>

    constructor() { }

    ngAfterViewInit() {
        this.modalDiv.nativeElement.appendChild(this.div_data);
        
    }

    closeModal() {
        this.close.emit();
    }

}
