<section #contentLoaded id="article-view-page">
    <div class="article-view-page">
        <div class="container-fluid">
            <!-- <div class="row ">
            <div class="col-9" > -->
            <div class="row  pe-4 ps-2  pt-3 view-section">
                <div class="col mb-5 me-2 content-area-sm">
                    <div class="row ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5  ms-0 d-flex flex-xs-column-reverse flex-sm-column-reverse
                             flex-md-column-reverse flex-lg-column-reverse  flex-xl-column-reverse flex-xxl-row">
                        <div class="col-lg-12 col-xl-6 col-xxl-5 ps-0">
                            <!-- <div *ngIf="!categorydocheadloadie categorydocheadNotLoaded">
                                <h1 *ngIf="articleView.category_dochead" class="about-article"
                                    [innerHtml]="articleView.category_dochead"></h1>
                            </div> -->
                            <div>
                                <h1 class="about-article">Microbiology</h1>
                            </div>

                        </div>
                        <ng-template #categorydocheadNotLoaded>
                            <div>
                                <h1 class="about-article skeleton"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h1>
                            </div>
                        </ng-template>
                        <div class="col-lg-12 col-xxl-7 d-flex justify-content-end wrapper-container">
                            <div class="article-view-wrapper d-flex  justify-content-center me-3 me-xs-0">
                                <span class="pe-4 pe-xs-0 d-flex jusitfy-content-center custom-color">
                                    <fa-icon [icon]="['fas','eye']" aria-hidden="true"
                                        class="mt-2 m-3 icon-class"></fa-icon>

                                </span>
                                <span class="me-2 article-view-normal "
                                    [ngStyle]="{'background-color': activeViewFont == 'normal' ? '#fff' : ''}"
                                    (click)="openDyslexic('normal')">
                                    Normal View </span>

                                <span class="article-view-dyslexic "
                                    [ngStyle]="{'background-color': activeViewFont == 'dyslexic' ? '#fff' : ''}"
                                    (click)="openDyslexic('dyslexic')">
                                    Dyslexic View
                                </span>
                            </div>
                            <div class="share-button d-flex justify-content-center custom-color">
                                <div class="dropdown">
                                    <span class="dropdown-toggle" type="button" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <fa-icon [icon]="['fas', 'share-nodes']" class="share-icon"></fa-icon>
                                    </span>
                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <span class="dropdown-item p-2" href="#">
                                                <share-button button="facebook" text="Share" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>

                                        <li>
                                            <span class="dropdown-item p-2" href="#">
                                                <share-button button="x" text="Twitter" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>

                                        <li>
                                            <span class="dropdown-item p-2" tabindex="0" title="Linkedin">
                                                <share-button button="linkedin" text="Linkedin" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>
                                        <li>
                                            <span class="dropdown-item p-2" tabindex="0" title="Email">
                                                <share-button button="email" text="Email" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>
                                        <li><span class="dropdown-item p-2">
                                                <share-button button="copy" text="Copy Link" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                        <ng-template #pageNotLoaded>
                            <div class="col-lg-12 col-xxl-7 d-flex justify-content-end wrapper-container">
                                <div class="article-view-wrapper d-flex  justify-content-center me-3 me-xs-0">
                                    <div class="skeleton">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="share-button d-flex justify-content-center">
                                    <div class="skeleton"></div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="row ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5">
                        <!-- <div class="col" *ngIf="!articleTitleLoading;else articleTitleNotLoaded">
                            <h1 class="article-title" [innerHtml]="articleView.article_title"></h1>
                        </div> -->
                        <div class="col" id="chapter-heading">
                            <h1 class="chapter-heading pt-2">
                                Anti-Leprosy Drugs: Modes of Action and Mechanisms of Resistance in Mycobacterium leprae
                            </h1>
                        </div>
                        <ng-template #articleTitleNotLoaded>
                            <div class="col">
                                <h1 class="article-title skeleton skeleton-text skeleton-heading mt-4"></h1>
                            </div>
                        </ng-template>
                    </div>
                    <div class=" ps-xs-0 ps-sm-0 ps-md-5 ps-lg-8 ps-xl-8 ps-xxl-8 d-flex reverse-sm-col mt-1">
                        <div class="col" id="author">
                            <h4 class="chapter-author">
                                Emmanuelle Cambau, Diana L Williams </h4>
                        </div>

                    </div>
                    <div class=" ps-xs-0 ps-sm-0 ps-md-5 ps-lg-8 ps-xl-8 ps-xxl-8 d-flex reverse-sm-col mt-2">
                        <div class="col citation" id="citation">
                            <span class="ps-3">
                                <fa-icon class="custom-color" [icon]="['fas', 'quote-left']"></fa-icon> Citation
                            </span>
                            <span class="citation-text">
                                Cambau E, Williams DL. (2019) Chapter 5.2. Anti-leprosy Drugs: Modes of Action and
                                Mechanisms of Resistance in Mycobacterium leprae. In Scollard DM, & Gillis TP. (Eds.),
                                International Textbook of Leprosy. American Leprosy Missions, Greenville, SC.
                                <a href="https://doi.org/10.1489/itl.5.2"
                                    target="_blank">https://doi.org/10.1489/itl.5.2</a>
                            </span>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9">

                            <!---------------article content section----------->
                            <div id="article-content" class="article-content">
                                <div id="introduction">
                                    <p class="chapter-title custom-color">Introduction</p>
                                    <p class="chapter-content">
                                        Although the implementation of long-term dapsone therapy made the effective
                                        treatment of leprosy possible [1], within a few decades resistance to this
                                        antibiotic was observed among patients undergoing treatment [2]. This resistance
                                        resulted in high rates of relapse, primarily due to inappropriate monotherapy
                                        (secondary resistance).
                                        As a consequence, dapsone-resistant leprosy was transmitted to susceptible
                                        persons,
                                        resulting in new cases of dapsone-resistant leprosy (primary resistance) [3].
                                        New drugs were subsequently identified as effective anti-leprosy drugs; however,
                                        acquired resistance to these drugs was also observed when they were used as a
                                        monotherapy [4], [5]. Consequently, a strategy developed for the treatment of
                                        tuberculosis [6] was implemented, in which multiple effective antibiotics were
                                        combined to prevent the selection of antibiotic-resistant strains.
                                        The multi-drug therapy (MDT) recommended in 1982 by the World Health
                                        Organization
                                        (WHO)
                                        as the standard treatment for leprosy is a combination of dapsone, rifampin,
                                        and clofazimine [7]. MDT has been effective at reducing both the prevalence and
                                        the incidence of leprosy (see Chapter 1.1; Chapter 1.2) at a global level [8],
                                        [9].
                                        However, drug resistance is still observed [10]. Approximately 211,973 new cases
                                        of
                                        leprosy were reported in 2015, demonstrating that the battle against leprosy
                                        continues
                                        and that research in chemotherapy must continue [11].</p>
                                    <p class="chapter-content"> In this review, we describe the antibacterial activity
                                        of
                                        the antibiotics
                                        used to treat leprosy and, if known, their mechanisms of resistance in M.
                                        leprae. We also describe the methods used to study antibiotic activity,
                                        drug susceptibility, and resistance, and report on the efforts to monitor
                                        global drug resistance in leprosy [12].</p>
                                </div>
                                <div id="modeOfAction">
                                    <p class="chapter-title custom-color"> Mode of Action and Antibacterial Activity</p>
                                    <p class="chapter-content">
                                        This discussion begins with the anti-leprosy drugs included in the standard
                                        WHO-recommended MDT
                                        treatment of leprosy: dapsone, rifampin, and clofazimine. Next, a number of
                                        newer
                                        antimicrobial
                                        agents that possess various degrees of bactericidal activity against M. leprae,
                                        such
                                        as the fluoroquinolones,
                                        the macrolides, and the tetracyclines, are discussed. These drugs have been
                                        described as effective in experimental
                                        infections in mice and in human clinical trials. The modes of action for most of
                                        the
                                        effective classes of drugs
                                        against M. leprae occur at the level of nucleic acid and protein synthesis.
                                        However,
                                        for many anti-leprosy drugs,
                                        the actual mechanisms of action are not known but inferred from studies of M.
                                        tuberculosis.
                                    <p>

                                </div>

                                <div id="dapsone">
                                    <p class="chapter-title custom-color"> Dapsone</p>
                                    <p class="chapter-content">

                                        The first effective treatment for leprosy was promin, a sodium glucosulfone
                                        (diamino-azobenzene 4’-sulfonamide)
                                        introduced in 1943 [13]. Six years later, a more effective oral sulfone, dapsone
                                        (4,4’-diaminodiphenylsulfone)
                                        (Figure 1A), replaced promin and is still a fundamental component of MDT for
                                        leprosy.
                                        Sulfone drugs target the dihydropteroate synthase (DHPS), a key enzyme in the
                                        folate
                                        biosynthesis pathway in bacteria (Figure 2), by acting as a competitive
                                        inhibitor of
                                        p-aminobenzoic acid
                                        (PABA) [14], [15]. The inability to synthesize folate leads to a depletion of
                                        adenosine,
                                        guanosine, and thymidine pools. The effect of dapsone on folate biosynthesis has
                                        been
                                        confirmed in M. leprae [16], [17].

                                    </p>

                                    <div class="figure-container">
                                        <div class="figure-content">
                                            <img alt="FIG5_2_1.png"
                                                src="https://www.internationaltextbookofleprosy.org/sites/default/files/FIG5_2_1.png"
                                                class="img-modal" figid="36890">
                                            <p class="figure-caption">FIG 1 Biochemical Structures of Anti-Leprosy
                                                Drugs.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="figure-container">
                                        <div class="figure-content">
                                            <img alt="FIG5_2_2.png"
                                                src="https://www.internationaltextbookofleprosy.org//sites/default/files/FIG5_2_2.png"
                                                class="img-modal" figid="68357">
                                            <p class="figure-caption">FIG 2 Inhibition of Folic Acid Biosynthesis by
                                                Dapsone.</p>
                                        </div>
                                    </div>
                                    <p class="chapter-content">
                                        Dapsone is a competitive inhibitor of para-amino benzoic acid (PABA),
                                        a critical substrate for folate biosynthesis. Dapsone blocks the condensation of
                                        PABA and 7,8-dyhydro-6-hydroxymethylpterin-pyrophosphate from forming
                                        7,8-dihydrofolic acid. The key enzyme in this step is the dihydropteroate
                                        synthase
                                        (DHPS) encoded by the folP gene. Dapsone competitively inhibits this
                                        condensation
                                        and ultimately results in the decreased production of
                                        tetrahydrofolate, an essential component of nucleic acid biosynthesis in M.
                                        leprae.
                                    </p>
                                </div>
                                <div id="dapsone-resistance">
                                    <p class="chapter-title custom-color"> Dapsone Resistance</p>
                                    <p class="chapter-content">
                                        Recognizing that dihydropteroate synthase (DHPS) is the target of sulfonamides and derivatives, including dapsone [68], 
                                        studies designed to characterize the mechanism of dapsone resistance in M. leprae have relied upon direct sequencing of 
                                        the two genes encoding DHPS1 and DHPS2 (folP1 and folP2, respectively) from dapsone-susceptible and -resistant M. leprae 
                                        isolates. Missense mutations were found within codons 53 and 55 of folP1 (Figure 5) in dapsone-resistant mutants [17], [69]. 
                                        Because no mutations were identified in the DHPS2 genes from the resistant mutant, folP2 was ruled out as a functional part 
                                        of dapsone resistance [70]. Mutations identified in the dapsone-resistant mutant resulted in a DHPS1 enzyme with decreased
                                         dapsone binding [17]. Since the same mutations were repeatedly observed in codons 53 and 55 of the folP1 gene in characterized 
                                         dapsone-resistant strains, this region was designated the “drug resistance-determining region” (DRDR) for dapsone resistance 
                                         [71] (Table 1). A search for folP1 mutations in M. leprae isolates demonstrated that all M. leprae strains with high-level 
                                         dapsone resistance (multiplication in mice fed with 0.01% dapsone), and the majority of M. leprae strains with moderate-level
                                          dapsone resistance (multiplication in mice fed with 0.001% dapsone) contained missense mutations within codons 53 and 55 
                                          [69], [71], [72]. One isolate with low-level resistance (multiplication in mice fed with 0.0001% dapsone but no higher
                                           concentrations) also contained a mutation in codon 55 [71].
                                    </p>
                                    <div class="table-w-caption">
                                        <table>
                                            <caption>TABLE 1 Mutations within anti-leprosy drug target genes that confer resistance to Mycobacterium leprae</caption>
                                            <thead>
                                                <tr>
                                                    <th>Drug<br>Susceptibility<br><span class="gloss-tooltip abbr" data-toggle="tooltip" data-original-title="mouse footpad assay for testing drug susceptibility in leprosy">MFP</span> Assay<sup>1</sup></th>
                                                    <th>Amino Acid Mutation<sup>2</sup></th>
                                                    <th>Number of<br>Resistant<br>Isolates</th>
                                                    <th>Reference</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colspan="4"><strong>Dapsone/folP1</strong></td>
                                                </tr>
                                                <tr>
                                                    <td class="center">R</td>
                                                    <td>Thr53Ala</td>
                                                    <td class="center">14</td>
                                                    <td class="center">
                                                        <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                        <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    <p class="rtecenter">R</p>
                                                    </td>
                                                    <td>
                                                    <p>Thr53Ala</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter">14</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter"><sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup><sup>, </sup><sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    <p class="rtecenter">NC</p>
                                                    </td>
                                                    <td>
                                                    <p>Thr53Ala; Pro55Leu</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter">1</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter"><sup data-footnote-id="ml4v9"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-74" id="footnote-marker-74-1" rel="footnote" data-toggle="tooltip" data-original-title="Maeda S, Matsuoka M, Nakata N, Kai M, Maeda Y, Hashimoto K, Kimura H, Kobayashi K, Kashiwabara Y. 2001. Multidrug resistant Mycobacterium leprae from patients with leprosy. Antimicrob Agents Chemother 45:3635–3639.">[74]</a></sup></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    <p class="rtecenter">R</p>
                                                    </td>
                                                    <td>
                                                    <p>Thr53Arg</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter">7</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter"><sup data-footnote-id="8zy98"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-71" id="footnote-marker-71-4" rel="footnote" data-toggle="tooltip" data-original-title="Cambau E, Carthagena L, Chauffour A, Ji B, Jarlier V. 2006. Dihydropteroate synthase mutations in the folP1 gene predict dapsone resistance in relapsed cases of leprosy. Clin Infect Dis 42:238–241.">[71]</a></sup><sup>, </sup><sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-2" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    <p class="rtecenter">R</p>
                                                    </td>
                                                    <td>
                                                    <p>Thr53Ile</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter">15</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter"><sup data-footnote-id="lni9l"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-17" id="footnote-marker-17-4" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Spring L, Harris E, Roche P, Gillis TP. 2000. Dihydropteroate synthase of Mycobacterium leprae and dapsone resistance. Antimicrob Agents Chemother 44:1530–1537.">[17]</a></sup><sup>, </sup><sup data-footnote-id="8zy98"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-71" id="footnote-marker-71-5" rel="footnote" data-toggle="tooltip" data-original-title="Cambau E, Carthagena L, Chauffour A, Ji B, Jarlier V. 2006. Dihydropteroate synthase mutations in the folP1 gene predict dapsone resistance in relapsed cases of leprosy. Clin Infect Dis 42:238–241.">[71]</a></sup><sup>, </sup><sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-3" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup><sup>, </sup><sup data-footnote-id="ml4v9"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-74" id="footnote-marker-74-2" rel="footnote" data-toggle="tooltip" data-original-title="Maeda S, Matsuoka M, Nakata N, Kai M, Maeda Y, Hashimoto K, Kimura H, Kobayashi K, Kashiwabara Y. 2001. Multidrug resistant Mycobacterium leprae from patients with leprosy. Antimicrob Agents Chemother 45:3635–3639.">[74]</a></sup></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                    <p class="rtecenter">R</p>
                                                    </td>
                                                    <td>
                                                    <p>Thr53Val</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter">3</p>
                                                    </td>
                                                    <td>
                                                    <p class="rtecenter"><sup data-footnote-id="8zy98"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-71" id="footnote-marker-71-6" rel="footnote" data-toggle="tooltip" data-original-title="Cambau E, Carthagena L, Chauffour A, Ji B, Jarlier V. 2006. Dihydropteroate synthase mutations in the folP1 gene predict dapsone resistance in relapsed cases of leprosy. Clin Infect Dis 42:238–241.">[71]</a></sup><sup>, </sup><sup data-footnote-id="mvk5b"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-75" id="footnote-marker-75-1" rel="footnote" data-toggle="tooltip" data-original-title="Cambau E, Chauffour-Nevejans A, Tejmar-Kolar L, Matsuoka M, Jarlier V. 2012. Detection of antibiotic resistance in leprosy using GenoType LepraeDR, a novel ready-to-use molecular test. PLoS Negl Trop Dis 6:e1739.">[75]</a></sup><sup>, </sup><sup data-footnote-id="ropui"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-76" id="footnote-marker-76-1" rel="footnote" data-toggle="tooltip" data-original-title="Matsuoka M, Budiawan T, Aye KS, Kyaw K, Tan EV, Cruz ED, Gelber R, Saunderson P, Balagon V, Pannikar V. 2007. The frequency of drug resistance mutations in Mycobacterium leprae isolates in untreated and relapsed leprosy patients from Myanmar, Indonesia and the Philippines. Lepr Rev 78:343–352.">[76]</a></sup></p>
                                                    </td>
                                                </tr>
                                                <!-- Add more rows as needed -->
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    

                                </div>
                                <div id="references">
                                    <p class="chapter-title custom-color">References</p>


                                    <ol class="chapter-references">
                                        <li data-footnote-id="q045x" id="footnote-1"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-1-1">^</a>
                                            </sup><cite><strong>Lowe</strong> <strong>J.</strong> 1950. Treatment of
                                                leprosy
                                                with diamino-diphenyl sulphone by mouth. Lancet
                                                <strong>1:</strong>145–150.</cite>
                                        </li>
                                        <li data-footnote-id="lnxdl" id="footnote-2"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-2-1">a</a>,
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-2-2">b</a>
                                            </sup><cite><strong>Pearson</strong> <strong>J,</strong>
                                                <strong>Rees</strong>
                                                <strong>R,</strong> <strong>Waters</strong> <strong>M.</strong> 1975.
                                                Sulfone resistance in leprosy. A review of 100 proven clinical cases.
                                                Lancet
                                                <strong>2:</strong>69–72.</cite>
                                        </li>
                                        <li data-footnote-id="0gy75" id="footnote-3"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-3-1">^</a>
                                            </sup><cite><strong>Pearson</strong> <strong>JM,</strong>
                                                <strong>Haile</strong>
                                                <strong>GS,</strong> <strong>Rees</strong> <strong>RJ.</strong> 1977.
                                                Primary dapsone-resistant leprosy. Lepr Rev
                                                <strong>48:</strong>129–132.</cite></li>
                                        <li data-footnote-id="tdfgd" id="footnote-4"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-4-1">^</a>
                                            </sup><cite><strong>Jacobson</strong> <strong><span
                                                        class="gloss-tooltip abbr" data-toggle="tooltip"
                                                        data-original-title="reversal reactions: Type 1 lepra reactions; associated with the development of M. leprae antigenic determinants; they are delayed hypersensitivity reactions and may occur in both paucibacillary leprosy and multibacillary leprosy (http://apps.who.int/medicinedocs/en/)
                                ">RR</span>,</strong> <strong>Hastings</strong> <strong>RC.</strong> 1976.
                                                Rifampin-resistant leprosy. Lancet <strong>2:</strong>1304–1305.</cite>
                                        </li>
                                        <li data-footnote-id="6my6m" id="footnote-5"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-5-1">a</a>,
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-5-2">b</a>,
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-5-3">c</a>,
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-5-4">d</a>
                                            </sup><cite><strong>Cambau</strong> <strong>E,</strong>
                                                <strong>Perani</strong>
                                                <strong>E,</strong> <strong>Guillemin</strong> <strong>I,</strong>
                                                <strong>Jamet</strong> <strong>P,</strong> <strong>Ji</strong>
                                                <strong>B.</strong> 1997. Multidrug-resistance to dapsone, rifampicin,
                                                and
                                                ofloxacin in Mycobacterium leprae. Lancet
                                                <strong>349:</strong>103–104.</cite></li>
                                        <li data-footnote-id="bdgjc" id="footnote-6"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-6-1">^</a>
                                            </sup><cite><strong>Cohn</strong> <strong>ML,</strong>
                                                <strong>Middlebrook</strong> <strong>G,</strong>
                                                <strong>Russell</strong>
                                                <strong>WF,</strong> <strong>Jr.</strong> 1959. Combined drug treatment
                                                of
                                                tuberculosis. I. Prevention of emergence of mutant populations of
                                                tubercle
                                                bacilli resistant to both streptomycin and isoniazid in vitro. J Clin
                                                Invest
                                                <strong>38:</strong>1349–1355.</cite></li>
                                        <li data-footnote-id="vldy2" id="footnote-7"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-7-1">a</a>,
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-7-2">b</a>,
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-7-3">c</a>
                                            </sup><cite><strong>WHO.</strong> 1982. Chemotherapy of leprosy for control
                                                programmes. World Health Organization, Geneva.</cite></li>
                                        <li data-footnote-id="7ytcb" id="footnote-8"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-8-1">a</a>,
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-8-2">b</a>
                                            </sup><cite><strong>Ji</strong> <strong>BH.</strong> 1985. Drug resistance
                                                in
                                                leprosy—a review. Lepr Rev <strong>56:</strong>265–278.</cite></li>
                                        <li data-footnote-id="x4f1w" id="footnote-9"><sup><a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-9-1">^</a>
                                            </sup><cite><strong>WHO.</strong> 2013. Global leprosy: update on the 2012
                                                situation. Weekly Epidem Record <strong>88:</strong>365–380.</cite></li>
                                        <li data-footnote-id="ohkvj" id="footnote-10"><sup>
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-10-1">a</a>,
                                                <a class="chapter-footnote"
                                                    href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-10-2">b</a>
                                            </sup>
                                            <cite><strong>WHO.</strong> 2011. Surveillance of drug resistance in
                                                leprosy.
                                                Weekly Epidem Record <strong>23:</strong>233–240.</cite>
                                        </li>


                                    </ol>
                                </div>




                            </div>

                        </div>
                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3"  (window:scroll)="onScroll($event)">
                            <div  id="chapter-tab" class="chapter-tab" [ngClass]="{ 'fixed-position': isFixed }"[ngStyle]="isFixed ? { 'width.px': containerWidth } : {}">
                                <div class="mb-3 mt-3">
                                    <button class="btn chapter-button mt-3" (click)="downloadPDF()">Download as
                                        PDF</button>
                                </div>
                                <!-- Tab navigation -->
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-bs-toggle="tab" href="#tab1"
                                            role="tab">Outline</a>
                                    </li>
                                    <li class="nav-item" *ngIf="chapter_info==true">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab2" role="tab">Chapter
                                            info</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab3" role="tab">References</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab4"
                                            role="tab">Authors|Citation</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab5" role="tab">Tables</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#tab6" role="tab">Images</a>
                                    </li>
                                </ul>

                                <!-- Tab content -->
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                                        <!-- Article Outline -->
                                        <div id="page-content" class="page-content pl-4 ml-1 page-container">
                                            <div class="row">
                                                <p class="chapter-title">Section outline</p>
                                                <div class="timeline block mb-4">
                                                    <div class="tl-item">
                                                        <div class="tl-dot b-warning secondary"></div>
                                                        <div #introduction (click)="scrollToSection('introduction')"
                                                            tabindex="0" class="tl-content secondary badge-content"
                                                            id="outlines0001">
                                                            Introduction
                                                        </div>
                                                    </div>
                                                    <div class="tl-item">
                                                        <div class="tl-dot b-warning secondary"></div>
                                                        <div #modeOfAction (click)="scrollToSection('modeOfAction')"
                                                            tabindex="0" class="tl-content secondary" id="outlines0002">
                                                            Mode of Action and Antibacterial Activity
                                                        </div>
                                                    </div>
                                                    <div class="tl-item">
                                                        <div class="tl-dot b-warning secondary"></div>
                                                        <div #dapsone (click)="scrollToSection('dapsone')" tabindex="0"
                                                            class="tl-content secondary" id="outlines0003">
                                                            Dapsone
                                                        </div>
                                                    </div>
                                                    <div class="tl-item">
                                                        <div class="tl-dot b-warning secondary"></div>
                                                        <div #dapsone (click)="scrollToSection('dapsone-resistance')" tabindex="0"
                                                            class="tl-content secondary" id="outlines0003">
                                                            Dapsone-resistance
                                                        </div>
                                                    </div>
                                                    <div class="tl-item">
                                                        <div class="tl-dot b-warning secondary"></div>
                                                        <div #references (click)="scrollToSection('references')"
                                                            tabindex="0" class="tl-content secondary" id="outlines0004">
                                                            References
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="tab2" role="tabpanel">
                                        <p>Content for Tab 2</p>
                                    </div>
                                    <div class="tab-pane fade" id="tab3" role="tabpanel">

                                        <div id="references">
                                            <p class="chapter-title">References</p>


                                            <ol class="chapter-references-tab">
                                                <li data-footnote-id="q045x" id="footnote-1"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-1-1">^</a>
                                                    </sup><cite><strong>Lowe</strong> <strong>J.</strong> 1950.
                                                        Treatment of leprosy
                                                        with diamino-diphenyl sulphone by mouth. Lancet
                                                        <strong>1:</strong>145–150.</cite>
                                                </li>
                                                <li data-footnote-id="lnxdl" id="footnote-2"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-2-1">a</a>,
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-2-2">b</a>
                                                    </sup><cite><strong>Pearson</strong> <strong>J,</strong>
                                                        <strong>Rees</strong>
                                                        <strong>R,</strong> <strong>Waters</strong> <strong>M.</strong>
                                                        1975.
                                                        Sulfone resistance in leprosy. A review of 100 proven clinical
                                                        cases. Lancet
                                                        <strong>2:</strong>69–72.</cite>
                                                </li>
                                                <li data-footnote-id="0gy75" id="footnote-3"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-3-1">^</a>
                                                    </sup><cite><strong>Pearson</strong> <strong>JM,</strong>
                                                        <strong>Haile</strong>
                                                        <strong>GS,</strong> <strong>Rees</strong> <strong>RJ.</strong>
                                                        1977.
                                                        Primary dapsone-resistant leprosy. Lepr Rev
                                                        <strong>48:</strong>129–132.</cite></li>
                                                <li data-footnote-id="tdfgd" id="footnote-4"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-4-1">^</a>
                                                    </sup><cite><strong>Jacobson</strong> <strong><span
                                                                class="gloss-tooltip abbr" data-toggle="tooltip"
                                                                data-original-title="reversal reactions: Type 1 lepra reactions; associated with the development of M. leprae antigenic determinants; they are delayed hypersensitivity reactions and may occur in both paucibacillary leprosy and multibacillary leprosy (http://apps.who.int/medicinedocs/en/)
                                                ">RR</span>,</strong> <strong>Hastings</strong> <strong>RC.</strong>
                                                        1976.
                                                        Rifampin-resistant leprosy. Lancet
                                                        <strong>2:</strong>1304–1305.</cite></li>
                                                <li data-footnote-id="6my6m" id="footnote-5"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-5-1">a</a>,
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-5-2">b</a>,
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-5-3">c</a>,
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-5-4">d</a>
                                                    </sup><cite><strong>Cambau</strong> <strong>E,</strong>
                                                        <strong>Perani</strong>
                                                        <strong>E,</strong> <strong>Guillemin</strong>
                                                        <strong>I,</strong>
                                                        <strong>Jamet</strong> <strong>P,</strong> <strong>Ji</strong>
                                                        <strong>B.</strong> 1997. Multidrug-resistance to dapsone,
                                                        rifampicin, and
                                                        ofloxacin in Mycobacterium leprae. Lancet
                                                        <strong>349:</strong>103–104.</cite></li>
                                                <li data-footnote-id="bdgjc" id="footnote-6"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-6-1">^</a>
                                                    </sup><cite><strong>Cohn</strong> <strong>ML,</strong>
                                                        <strong>Middlebrook</strong> <strong>G,</strong>
                                                        <strong>Russell</strong>
                                                        <strong>WF,</strong> <strong>Jr.</strong> 1959. Combined drug
                                                        treatment of
                                                        tuberculosis. I. Prevention of emergence of mutant populations
                                                        of tubercle
                                                        bacilli resistant to both streptomycin and isoniazid in vitro. J
                                                        Clin Invest
                                                        <strong>38:</strong>1349–1355.</cite></li>
                                                <li data-footnote-id="vldy2" id="footnote-7"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-7-1">a</a>,
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-7-2">b</a>,
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-7-3">c</a>
                                                    </sup><cite><strong>WHO.</strong> 1982. Chemotherapy of leprosy for
                                                        control
                                                        programmes. World Health Organization, Geneva.</cite></li>
                                                <li data-footnote-id="7ytcb" id="footnote-8"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-8-1">a</a>,
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-8-2">b</a>
                                                    </sup><cite><strong>Ji</strong> <strong>BH.</strong> 1985. Drug
                                                        resistance in
                                                        leprosy—a review. Lepr Rev <strong>56:</strong>265–278.</cite>
                                                </li>
                                                <li data-footnote-id="x4f1w" id="footnote-9"><sup><a
                                                            class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-9-1">^</a>
                                                    </sup><cite><strong>WHO.</strong> 2013. Global leprosy: update on
                                                        the 2012
                                                        situation. Weekly Epidem Record
                                                        <strong>88:</strong>365–380.</cite></li>
                                                <li data-footnote-id="ohkvj" id="footnote-10"><sup>
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-10-1">a</a>,
                                                        <a class="chapter-footnote"
                                                            href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=chapter#footnote-marker-10-2">b</a>
                                                    </sup>
                                                    <cite><strong>WHO.</strong> 2011. Surveillance of drug resistance in
                                                        leprosy.
                                                        Weekly Epidem Record <strong>23:</strong>233–240.</cite>
                                                </li>


                                            </ol>
                                        </div>

                                    </div>
                                    <div class="tab-pane fade" id="tab4" role="tabpanel">
                                        <div class="view-content">
                                            <div class="contributor-type-list">
                                                <p class="chapter-title">Contributing Authors</p>
                                                <div class="chapter-content">
                                                    <p><strong>Emmanuelle Cambau</strong><br>
                                                        MD, PhD<br>
                                                        Professor of Bacteriology<br>
                                                        School of Medicine<br>
                                                        University Paris Diderot<br>
                                                        Square Villemin, 75010 Paris, France</p>


                                                    <p>Head of Microbiology Laboratory<br>
                                                        Assistance Publique-Hôpitaux de Paris Hopital Lariboisiere<br>
                                                        2 rue Ambroise Pare, 75010 Paris, France</p>
                                                    <hr>
                                                    <p>
                                                       <strong> DianaLWilliams</strong> <br>
                                                        BS, MS, PhD<br>
                                                        Adjunct Associate Professor<br>
                                                        Pathobiological Sciences Department<br>
                                                        Louisiana State University School of Veterinary Medicine<br>
                                                        Skip Bertman Drive<br>
                                                        Baton Rouge, LA 70803 USA
                                                    </p><br>
                                                    
                                                    <div class="col citation-tab" id="citation">
                                                        <span class="ps-3">
                                                            <fa-icon class="custom-color" [icon]="['fas', 'quote-left']"></fa-icon> Citation
                                                        </span>
                                                        <span class="citation-text-tab">
                                                            Cambau E, Williams DL. (2019) Chapter 5.2. Anti-leprosy Drugs: Modes of Action and
                                                            Mechanisms of Resistance in Mycobacterium leprae. In Scollard DM, & Gillis TP. (Eds.),
                                                            International Textbook of Leprosy. American Leprosy Missions, Greenville, SC.
                                                            <a href="https://doi.org/10.1489/itl.5.2"
                                                                target="_blank">https://doi.org/10.1489/itl.5.2</a>
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="tab5" role="tabpanel">
                                        <div class="table-w-caption-tab">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Drug<br>Susceptibility<br><span class="gloss-tooltip abbr" data-toggle="tooltip" data-original-title="mouse footpad assay for testing drug susceptibility in leprosy">MFP</span> Assay<sup>1</sup></th>
                                                        <th>Amino Acid Mutation<sup>2</sup></th>
                                                        <th>Number of<br>Resistant<br>Isolates</th>
                                                        <th>Reference</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="4"><strong>Dapsone/folP1</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center">R</td>
                                                        <td>Thr53Ala</td>
                                                        <td class="center">14</td>
                                                        <td class="center">
                                                            <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                            <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center">R</td>
                                                        <td>Thr53Ala</td>
                                                        <td class="center">14</td>
                                                        <td class="center">
                                                            <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                            <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center">R</td>
                                                        <td>Thr53Ala</td>
                                                        <td class="center">14</td>
                                                        <td class="center">
                                                            <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                            <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="center">R</td>
                                                        <td>Thr53Ala</td>
                                                        <td class="center">14</td>
                                                        <td class="center">
                                                            <sup data-footnote-id="alhmr"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-72" id="footnote-marker-72-2" rel="footnote" data-toggle="tooltip" data-original-title="Kai M, Matsuoka M, Nakata N, Maeda S, Gidoh M, Maeda Y, Hashimoto K, Kobayashi K, Kashiwabara Y. 1999. Diaminodiphenylsulfone resistance of Mycobacterium leprae due to mutations in the dihydropteroate synthase gene. FEMS Microbiology Letters 177:231–235.">[72]</a></sup>
                                                            <sup data-footnote-id="r3v0s"><a href="/chapter/anti-leprosy-drugs-modes-action-and-mechanisms-resistance-mycobacterium-leprae?sect=references#footnote-73" id="footnote-marker-73-1" rel="footnote" data-toggle="tooltip" data-original-title="Williams DL, Pittman TL, Gillis TP, Matsuoka M, Kashiwabara Y. 2001. Simultaneous detection of Mycobacterium leprae and its susceptibility to dapsone using DNA heteroduplex analysis. J Clin Microbiol 39:2083–2088.">[73]</a></sup>
                                                        </td>
                                                    </tr>
                                                 
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div  class="tab-pane fade" id="tab6" role="tabpanel">
                                        <div class="figure-container">
                                            <div class="figure-content">
                                                <img alt="FIG5_2_1.png"
                                                    src="https://www.internationaltextbookofleprosy.org/sites/default/files/FIG5_2_1.png"
                                                    class="img-modal" figid="36890">
                                              
                                            </div>
                                        </div>
    
                                        <div class="figure-container">
                                            <div class="figure-content">
                                                <img alt="FIG5_2_2.png"
                                                    src="https://www.internationaltextbookofleprosy.org//sites/default/files/FIG5_2_2.png"
                                                    class="img-modal" figid="68357">
                                              
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</section>