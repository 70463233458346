<div class="col-12" *ngIf="!pageLoading; else noFilterLoaded">
  <div class="card filter-section" #filterCard>
    <div class="card-body" *ngIf="surgicalView || contentTypeView">
      <div class="filter-tag-section" *ngIf="surgicalView && uniqueSurgicalSpecialities.length > 0">
        <h5 class="section-heading">Surgical Speciality</h5>
        <div class="filter-badge">
          <span *ngFor="let speciality of uniqueSurgicalSpecialities"
                class="filter-tag"
                [class.selected]="isSpecialitySelected(speciality)"
                (click)="onSpecialityChange(speciality)">
            {{ speciality }}
          </span>
        </div>
      </div>
      <div class="filter-tag-section" *ngIf="contentTypeView && uniqueContentTypes.length > 0">
        <h5 class="section-heading">Content Type</h5>
        <div class="filter-badge">
          <span *ngFor="let type of uniqueContentTypes"
                class="filter-tag"
                [class.selected]="isContentTypeSelected(type)"
                (click)="onContentTypeChange(type)">
            {{ type }}
          </span>
        </div>
      </div>
      <button class="clear-filters-btn" (click)="clearFilters()">Clear filters</button>
    </div>
  </div>
</div>
<ng-template #noFilterLoaded>
  <div class="col-12" >
    <div class="card filter-section" #filterCard>
      <div class="card-body" >
        <div class="filter-tag-section">
          <h5 class="section-heading skeleton"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h5>
          <div class="filter-badge ">
            <span *ngFor="let speciality of loadingArray"
                  class="filter-tag skeleton border-0"
                  >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
          </div>
        </div>
        <div class="filter-tag-section" >
          <h5 class="section-heading">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</h5>
          <div class="filter-badge ">
            <span *ngFor="let type of loadingArray"
                  class="filter-tag skeleton border-0"
                  >
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
          </div>
        </div>
        <button class="clear-filters-btn skeleton" >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</button>
      </div>
    </div>
  </div>
  
</ng-template>