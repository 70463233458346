<div class="modal-backdrop" (click)="closeModal()"></div>
<div class="modal-container">
    <div class="modal-content" #modalContent>
        <!-- <div class="fig">
            <div class="fig-caption">
                <span class="figure"> Figure 1.</span>
                <span class="fig-content">
                    Anatomical details of clasp (A) and sling (B) fibres from robotic
                    Heller myotomy
                </span>
            </div>
            <img src="large-image.png" alt="" height="500" class="large-image">
        </div> -->
    </div>
</div>