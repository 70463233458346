import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticlesRoutingModule } from './articles-routing.module';
import { ArticleListingComponent } from './article-listing/article-listing.component';
import { ArticleFilterComponent } from './article-filter/article-filter.component';
import { ReactiveFormsModule } from '@angular/forms';

import { GeneralModule } from '../general/general.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
   
    ArticleListingComponent,
    ArticleFilterComponent,
  ],
    
  imports: [
    CommonModule,
    ArticlesRoutingModule,
    ReactiveFormsModule,
    GeneralModule,    
    FontAwesomeModule,
  ],
  
})
export class ArticlesModule { }
