import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChapterViewPageComponent } from './chapter/chapter-view/chapter-view-page.component';
import { PageNotFoundComponent } from './general/page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
  { path: 'chapter', component: ChapterViewPageComponent },

  // { path: '', component: HomeComponent },
  // { path: 'article-view-page', component: ArticleViewPageComponent },
  // {path:'young-bjs',component:ArticleListingComponent}
  // {
	// 	path: '',
	// 	component:HomeComponent
	// }, // Redirect root to /home
  // { path: '', component: HomeComponent },
   {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  // {
  //   path: 'academy', 
  //   loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule)
  // },
  {
    path: 'page',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule)

  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found' }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


